@font-face {
  font-family: "Montserrat";
  src: url("https://storage.googleapis.com/varal-habot-vault-marketplace-10032022/fonts/Montserrat-Regular.ttf")
      format("woff2"),
    url("https://storage.googleapis.com/varal-habot-vault-marketplace-10032022/fonts/Montserrat-Regular.ttf")
      format("woff");
  font-weight: normal;
  font-style: normal;
}
body {
  background-color: #fff;
  margin: 0;
  font-family: "Montserrat";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Montserrat";
}
@media only screen and (max-width: 991px) {
  .dtwidget-chat-container {
    bottom: 75px !important;
  }
}
.dtwidget-chatmodal-header > span {
  font-family: "Montserrat";
}
.dtwidget-chatmodal-body > form > div > textarea,
.dtwidget-chatmodal-body > [data-form="dt"] > div > textarea {
  font-family: "Montserrat";
}
.dtwidget-chatmodal-body > form > button,
.dtwidget-chatmodal-body > form > [data-role="button"],
.dtwidget-chatmodal-body > [data-form="dt"] > button,
.dtwidget-chatmodal-body > [data-form="dt"] > [data-role="button"] {
  font-family: "Montserrat";
}
.dtwidget-chatmodal-footer {
  display: none !important;
}
/* .job-cont {
  overflow-x: auto; 
  overflow-y: auto;
  max-height: 80vh; 
  scrollbar-width: thin; 
  scrollbar-color: #888 transparent; 
}

.job-cont::-webkit-scrollbar {
  height: 5px; 
}

.job-cont::-webkit-scrollbar-thumb {
  background-color: #888; 
  border-radius: 10px; 
}

.job-cont::-webkit-scrollbar-track {
  background: transparent; 
} */
.job_tab {
  margin: 10px !important;
  border: none;
}
table thead tr td {
  font-size: 15px;
}
table tbody tr td {
  font-size: 13px;
}
.sub_title_form {
  margin-left: 10px;
}

.job_tab thead {
  position: sticky;
  top: 0;
  background-color: #072f57 !important;
  z-index: 999;
}

.table-container {
  overflow-x: auto;
  overflow-y: auto;
  max-height: 70vh;
}

.job_tab {
  width: 100%;
  border-collapse: collapse;
}

.job_tab thead {
  position: sticky;
  top: 0;
  background-color: #072f57 !important;
  z-index: 999;
}

.table-container::-webkit-scrollbar {
  width: 10px;
  height: 15px;
}

.table-container::-webkit-scrollbar-thumb {
  background-color: #072f57;
  border-radius: 5px;
}

.table-container::-webkit-scrollbar-track {
  background: transparent;
}
